

import { ref } from 'vue';
import { useSdk } from './use-mycure';


export function useDentalStatus () {
  const sdk = useSdk();
  const SERVICE_NAME = 'fixtures';

  const customDentalStatuses = ref([]);

  /**
   * @param {String} opts.uid - UID of the user
   * @returns {Promise<Array>} - List of fixtures
   */
  async function listItems (opts) {
    const currentUser = await sdk?.currentUser();
    const uid = currentUser?.uid;

    const query = Object.assign({
      type: 'dental-status',
      account: uid,
      $sort: {
        createdAt: -1,
      },
    }, opts);

    const { data } = await sdk.list(SERVICE_NAME, query);

    customDentalStatuses.value = data;

    // TODO: handle mapping if applicable
    return data;
  }

  /**
   * @returns {Promise<Object>} - Fixture
   */
  async function listDefaultItems () {
    const query = {
      type: 'dental-status',
      account: {
        $exists: false,
      },
    };

    const { data } = await sdk.list('fixtures', query);
    return data;
  }

  async function getItem (id) {
    const data = await sdk.get(SERVICE_NAME, id);
    // TODO: handle mapping if applicable
    return data;
  }

  /**
   * 
   * @param {String} opts.abbreviation - Abbreviation of the status
   * @param {String} opts.account - UID of the user
   * @param {String} opts.category - Category of the status
   * @param {String} opts.description - Description of the status
   * @param {String} opts.colorCoding - Color coding of the status
   * @param {Boolean} opts.forAll - If the status is for all
   * @param {String} opts.statusType - Type of the status
   * @param {String} opts.type - Type of the fixture 
   * @returns {Promise<Object>} - Created fixture
   */
  async function createItem (data) {
    const currentUser = await sdk?.currentUser();
    const uid = currentUser?.uid;

    const payload = Object.assign({
      type: 'dental-status',
      account: uid,
    }, data);

    const res = await sdk.create(SERVICE_NAME, payload);

    customDentalStatuses.value.unshift(res);

    return res;
  }

  async function updateItem (id, data) {

    const res = await sdk.update(SERVICE_NAME, id, data);

    const index = customDentalStatuses.value.findIndex(item => item.id === id);

    if (index > -1) {
      customDentalStatuses.value[index] = res;
    }

    return res;
  }

  async function removeItem (id) {
    const res = await sdk.delete(SERVICE_NAME, id);

    const index = customDentalStatuses.value.findIndex(item => item.id === id);

    if (index > -1) {
      customDentalStatuses.value.splice(index, 1);
    }

    return res;
  }

  return {
    customDentalStatuses,
    listItems,
    listDefaultItems,
    getItem,
    createItem,
    updateItem,
    removeItem,
  }
};
